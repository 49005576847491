import React from 'react';
import "../App.css";
import HeroComponent from './HeroComponent';
import Card from './HomeCards';
function Home() {
  return (
    <>
    <div> 
    <HeroComponent/>
      <Card>
      </Card>

      </div>
    </>
  );
}

export default Home;