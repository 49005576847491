// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    height: 100%;
    margin: 0;
  }
  
  .footer {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    color: white;
    padding: 20px;
    text-align: center;
    position: relative;
    bottom:0;
    width: 100%;
    box-sizing: border-box;
  }
  
  .container {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto;
    padding: 0 20px;
    flex:1 1;
  }
  
  a {
    color: #f1c40f;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;EACX;;EAEA;IACE,4EAA4E;IAC5E,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,sBAAsB;EACxB;;EAEA;IACE,4EAA4E;IAC5E,iBAAiB,EAAE,qBAAqB;IACxC,cAAc;IACd,eAAe;IACf,QAAM;EACR;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":["html, body {\r\n    height: 100%;\r\n    margin: 0;\r\n  }\r\n  \r\n  .footer {\r\n    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);\r\n    color: white;\r\n    padding: 20px;\r\n    text-align: center;\r\n    position: relative;\r\n    bottom:0;\r\n    width: 100%;\r\n    box-sizing: border-box;\r\n  }\r\n  \r\n  .container {\r\n    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);\r\n    max-width: 1200px; /* Adjust as needed */\r\n    margin: 0 auto;\r\n    padding: 0 20px;\r\n    flex:1;\r\n  }\r\n  \r\n  a {\r\n    color: #f1c40f;\r\n    text-decoration: none;\r\n  }\r\n  \r\n  a:hover {\r\n    text-decoration: underline;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
